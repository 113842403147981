<template>
    <b-row class="match-height">
        <b-col lg="8">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>
                        New Inventory Data
                    </b-card-title>

                    <!-- dropdown button -->
                    <b-button-group class="mt-1 mt-sm-0">
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                        >
                            Daily
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                        >
                            Monthly
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                        >
                            Yearly
                        </b-button>
                    </b-button-group>
                    <!--/ dropdown button -->
                </b-card-header>

                <!-- chart -->
                <b-card-body>
                    <chartjs-component-scatter-chart
                        :height="415"
                        :data="scatterChart.data"
                        :options="scatterChart.options"
                    />
                </b-card-body>
            </b-card>
        </b-col>
        <b-col lg="4">
            <b-card title="EU FBA orders">
                <!-- chart -->
                <chartjs-component-doughnut-chart
                    :height="275"
                    :data="datapie.data"
                    :options="datapie.options"
                    class="mb-3"
                />
                <!--/ chart -->

                <!-- stocks -->
                <div
                    v-for="(stock,index) in stockData"
                    :key="stock.device"
                    :class="index < stockData.length-1 ? 'mb-1':''"
                    class="d-flex justify-content-between"
                >
                    <div class="d-flex align-items-center">
                        <feather-icon
                            :icon="stock.symbol"
                            size="16"
                            :class="stock.color"
                        />
                        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
                        <span>- {{ stock.percentage }}%</span>
                    </div>
                    <div>
                        <span>{{ stock.upDown }}%</span>
                        <feather-icon
                            :icon="stock.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
                            :class="stock.upDown > 0 ? 'text-success':'text-danger'"
                        />
                    </div>
                </div>
                <!--/ stocks -->
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardHeader,
    BCol,
    BRow,
    BButton,
    BButtonGroup,
    BCardBody,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ChartjsComponentDoughnutChart from '../components/Charts/ChartjsComponentDoughnutChart.vue'
import ChartjsComponentScatterChart from '../components/Charts/CharjsComponentScatterChart.vue'

const chartColors = {
    primaryColorShade: '#836AF9',
    yellowColor: '#ffe800',
    successColorShade: '#28dac6',
    warningColorShade: '#ffe802',
    warningLightColor: '#FDAC34',
    infoColorShade: '#299AFF',
    greyColor: '#4F5D70',
    blueColor: '#2c9aff',
    blueLightColor: '#84D0FF',
    greyLightColor: '#EDF1F4',
    tooltipShadow: 'rgba(0, 0, 0, 0.25)',
    lineChartPrimary: '#666ee8',
    lineChartDanger: '#ff4961',
    labelColor: '#6e6b7b',
    grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
    components: {
        ChartjsComponentDoughnutChart,
        ChartjsComponentScatterChart,
        BCard,
        BCardBody,
        BCol,
        BRow,
        BButton,
        BCardTitle,
        BCardHeader,
        BButtonGroup,
    },
    data() {
        return {
            scatterChart: {
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 800,
                    title: {
                        display: false,
                        text: 'Chart.js Scatter Chart',
                    },
                    tooltips: {
                        // Updated default tooltip UI
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowBlur: 8,
                        shadowColor: chartColors.tooltipShadow,
                        backgroundColor: $themeColors.light,
                        titleFontColor: $themeColors.dark,
                        bodyFontColor: $themeColors.dark,
                    },
                    scales: {
                        xAxes: [
                            {
                                gridLines: {
                                    color: chartColors.grid_line_color,
                                    zeroLineColor: chartColors.grid_line_color,
                                },
                                ticks: {
                                    stepSize: 10,
                                    min: 0,
                                    max: 140,
                                    fontColor: chartColors.labelColor,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                gridLines: {
                                    color: chartColors.grid_line_color,
                                    zeroLineColor: chartColors.grid_line_color,
                                },
                                ticks: {
                                    stepSize: 100,
                                    min: 0,
                                    max: 400,
                                    fontColor: chartColors.labelColor,
                                },
                            },
                        ],
                    },
                    legend: {
                        position: 'top',
                        align: 'start',
                        display: true,
                        labels: {
                            usePointStyle: true,
                            padding: 15,
                            boxWidth: 7,
                        },
                    },
                },
                data: {
                    datasets: [
                        {
                            label: 'iPhone',
                            data: [
                                {
                                    x: 72,
                                    y: 225,
                                },
                                {
                                    x: 81,
                                    y: 270,
                                },
                                {
                                    x: 90,
                                    y: 230,
                                },
                                {
                                    x: 103,
                                    y: 305,
                                },
                                {
                                    x: 103,
                                    y: 245,
                                },
                                {
                                    x: 108,
                                    y: 275,
                                },
                                {
                                    x: 110,
                                    y: 290,
                                },
                                {
                                    x: 111,
                                    y: 315,
                                },
                                {
                                    x: 109,
                                    y: 350,
                                },
                                {
                                    x: 116,
                                    y: 340,
                                },
                                {
                                    x: 113,
                                    y: 260,
                                },
                                {
                                    x: 117,
                                    y: 275,
                                },
                                {
                                    x: 117,
                                    y: 295,
                                },
                                {
                                    x: 126,
                                    y: 280,
                                },
                                {
                                    x: 127,
                                    y: 340,
                                },
                                {
                                    x: 133,
                                    y: 330,
                                },
                            ],
                            backgroundColor: $themeColors.primary,
                            borderColor: 'transparent',
                            pointBorderWidth: 2,
                            pointHoverBorderWidth: 2,
                            pointRadius: 5,
                        },
                        {
                            label: 'Samsung Note',
                            data: [
                                {
                                    x: 13,
                                    y: 95,
                                },
                                {
                                    x: 22,
                                    y: 105,
                                },
                                {
                                    x: 17,
                                    y: 115,
                                },
                                {
                                    x: 19,
                                    y: 130,
                                },
                                {
                                    x: 21,
                                    y: 125,
                                },
                                {
                                    x: 35,
                                    y: 125,
                                },
                                {
                                    x: 13,
                                    y: 155,
                                },
                                {
                                    x: 21,
                                    y: 165,
                                },
                                {
                                    x: 25,
                                    y: 155,
                                },
                                {
                                    x: 18,
                                    y: 190,
                                },
                                {
                                    x: 26,
                                    y: 180,
                                },
                                {
                                    x: 43,
                                    y: 180,
                                },
                                {
                                    x: 53,
                                    y: 202,
                                },
                                {
                                    x: 61,
                                    y: 165,
                                },
                                {
                                    x: 67,
                                    y: 225,
                                },
                            ],
                            backgroundColor: chartColors.yellowColor,
                            borderColor: 'transparent',
                            pointRadius: 5,
                        },
                        {
                            label: 'OnePlus',
                            data: [
                                {
                                    x: 70,
                                    y: 195,
                                },
                                {
                                    x: 72,
                                    y: 270,
                                },
                                {
                                    x: 98,
                                    y: 255,
                                },
                                {
                                    x: 100,
                                    y: 215,
                                },
                                {
                                    x: 87,
                                    y: 240,
                                },
                                {
                                    x: 94,
                                    y: 280,
                                },
                                {
                                    x: 99,
                                    y: 300,
                                },
                                {
                                    x: 102,
                                    y: 290,
                                },
                                {
                                    x: 110,
                                    y: 275,
                                },
                                {
                                    x: 111,
                                    y: 250,
                                },
                                {
                                    x: 94,
                                    y: 280,
                                },
                                {
                                    x: 92,
                                    y: 340,
                                },
                                {
                                    x: 100,
                                    y: 335,
                                },
                                {
                                    x: 108,
                                    y: 330,
                                },
                            ],
                            backgroundColor: chartColors.successColorShade,
                            borderColor: 'transparent',
                            pointBorderWidth: 2,
                            pointHoverBorderWidth: 2,
                            pointRadius: 5,
                        },
                    ],
                },
            },
            datapie: {
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 500,
                    cutoutPercentage: 60,
                    legend: { display: false },
                    tooltips: {
                        callbacks: {
                            label(tooltipItem, data) {
                                const label = data.datasets[0].labels[tooltipItem.index] || ''
                                const value = data.datasets[0].data[tooltipItem.index]
                                const output = ` ${label} : ${value} %`
                                return output
                            },
                        },
                        // Updated default tooltip UI
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowBlur: 8,
                        shadowColor: 'rgba(0, 0, 0, 0.25)',
                        backgroundColor: $themeColors.light,
                        titleFontColor: $themeColors.dark,
                        bodyFontColor: $themeColors.dark,
                    },
                },
                data: {
                    datasets: [
                        {
                            labels: ['DE', 'UK', 'FR', 'IT', 'ES', 'NL', 'PL'],
                            data: [39, 15, 14, 10, 10, 7, 5],
                            backgroundColor: [$themeColors.primary, $themeColors.secondary, $themeColors.success, $themeColors.info, $themeColors.danger, $themeColors.light, $themeColors.dark],
                            borderWidth: 0,
                            pointStyle: 'rectRounded',
                        },
                    ],
                },
            },
            stockData: [
                {
                    device: 'Germany', symbol: 'ArrowRightIcon', color: 'text-primary', percentage: 39, upDown: 2,
                },
                {
                    device: 'United Kingdom', symbol: 'ArrowRightIcon', color: 'text-secondary', percentage: 15, upDown: 25,
                },
                {
                    device: 'France', symbol: 'ArrowRightIcon', color: 'text-success', percentage: 14, upDown: 0,
                },
                {
                    device: 'Italy', symbol: 'ArrowRightIcon', color: 'text-info', percentage: 10, upDown: 0,
                },
                {
                    device: 'Spain', symbol: 'ArrowRightIcon', color: 'text-danger', percentage: 10, upDown: 9,
                },
                {
                    device: 'Netherlands', symbol: 'ArrowRightIcon', color: 'text-light', percentage: 7, upDown: -5,
                },
                {
                    device: 'Poland', symbol: 'ArrowRightIcon', color: 'text-dark', percentage: 5, upDown: -1,
                },
            ],
        }
    },
}
</script>
